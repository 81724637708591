import { render, staticRenderFns } from "./KeyDatesList.vue?vue&type=template&id=33017fe4&scoped=true&"
import script from "./KeyDatesList.vue?vue&type=script&lang=js&"
export * from "./KeyDatesList.vue?vue&type=script&lang=js&"
import style0 from "./KeyDatesList.vue?vue&type=style&index=0&id=33017fe4&lang=scss&scoped=true&"
import style1 from "./KeyDatesList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33017fe4",
  null
  
)

export default component.exports